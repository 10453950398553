import React from "react";
import Banner from "../components/Banner";
import { Helmet } from "react-helmet";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Cta from "../components/service/Cta";
import ServiceTab from "../components/ServiceTab";
import Clients from "../components/service/Clients";
import Testimonials from "../components/Testimonials";
import ProjectSection from "../components/ProjectSection";
import Faq from "../components/Faq";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import Layout from "../components/layout";

const ServiceDetail = () => {
  const banner = {
    title:  `Webflow Development Agency`,
    content:
      "We are the top React JS development company, delivering reactive solutions using our experience and technical breakthroughs to build a highly functioning interface.",
  };

  const commonContent6 = {
    title: "Webflow Development Services",
    para: "Our Webflow development services enable you to provide your customers with engaging, multichannel, and device-responsive web experiences.",
    img: ["what-service-image-webflow.webp"],
    imageTitle: 'Webflow Development Services',
    imageAlt: 'We are a leading Webflow development company',
    content: [
      {
        title: "Webflow Maintenance",
        text: "As the best Webflow agency, we provide hosting on Webflow's infrastructure, guaranteeing that websites are up-to-date, secure, and error-free.",
      },
      {
        title: "Webflow Support",
        text: "Our Webflow development company helps you and your team use Webflow more effectively. We ensure that you are well-prepared to manage and troubleshoot technological issues.",
      },
      {
        title: "Custom Webflow Integrations",
        text: "Octet provides tailored Webflow connections, which connect your website to third-party services like CRM systems, email marketing platforms, and analytics tools.",
      },
      {
        title: "A/B Testing and Analysis",
        text: "We Offer A/B testing and analytics services to improve your Webflow website's performance and conversion rates. We meticulously monitor user activity and gather data-driven insights.",
      },
      {
        title: "Webflow E-Com Development",
        text: "We ensure that our Webflow development services provide your clients with a safe and secure online shopping experience while allowing you to manage and scale your e-store.",
      },
      {
        title: "Web Design",
        text: "Our Webflow website development services begin the critical website creation phase with Webflow. These designs provide an exciting and memorable online experience.",
      },
    ],
  };

  const commonContent = {
    title: "Upscaling Industries with our Webflow Development Company",
    para: "Our Webflow Development Company is formed to match your organization's needs, allowing you to include cutting-edge functionality. ",
    img: ["who-needs-webflow.webp"],
    imageTitle: 'Webflow Development',
    imageAlt: 'Driving industry growth with our Webflow website development services',
    content: [
      {
        title: "E-commerce",
        text: "Companies seek to create dynamic and highly functional websites to give seamless website experiences to their customers.",
      },
      {
        title: "SMBs",
        text: "Companies are looking to boost their online presence using low-cost alternatives for designing websites based on consumer requirements.",
      },
      {
        title: "Startups",
        text: "Companies looking to grow their business by embracing Webflow’s cutting-edge tools and technology.",
      },
      {
        title: "Enterprises",
        text: "Companies that wish to simplify their complex platform while providing their clients with a high-performing, reliable platform for a better experience.",
      },
    ],
  };

  const commonContent2 = {
    title: "Retain Quality with the Best Webflow Development Agency",
    para: "As the best Webflow Design Agency, we try to achieve the best possible results for the projects we design and create.",
    img: ["what-are-benefits-webflow-services.webp"],
    imageTitle: 'Best Webflow Agency',
    imageAlt: 'choose better by choosing Octet',
    content: [
      {
        title: "Increased Efficiency",
        text: "Our Webflow agency provides reusable components and streamlines workflows, reducing development time and increasing productivity.",
      },
      {
        title: "Scalability",
        text: "Webflow development services provide scalable solutions for your business, keeping your website updated with the latest trends while ensuring performance.",
      },
      {
        title: "Enhanced User Experience",
        text: "Webflow website development services help create visually interactive, functional, responsive websites that improve user experience.",
      },
      {
        title: "Extensive Ecosystem",
        text: "Our Webflow development company offers an innovative solution for your business through its extensive plugins, community-driven tools, and libraries.",
      },
    ],
  };
  const commonContent3 = {
    title: "Top Webflow Development Agency to Thrive Businesses",
    linkText: ``,
    para: `Octet Design Studio is a top Webflow development agency that creates innovative interfaces using our significant industry knowledge. We use our expertise in Webflow development services to develop customized and robust online solutions for your company.`,
    img: ["why-opt-for-webflow.webp"],
    imageTitle: 'Top Webflow Development Company',
    imageAlt: 'Work with the best Webflow development company',
    content: [
      {
        title: "Expertise And Experience",
        text: "We have appropriate expertise and experience in providing Webflow Development and better-than-expected results.",
      },
      {
          title: "Timely Delivery",
          text: "We streamline the development process to ensure your projects run smoothly and on time.",
        },
        {
          title: "Collaborative Approach",
          text: "We take a personalized approach to each project and provide tailored Webflow website development services depending on your business objectives.",
        },
      {
        title: "Dedicated Team",
        text: "We provide a specialized team for your Webflow development, who will turn your ideas into reality and ensure timely delivery.",
      },
    ],
  };
  const commonContent4 = {
    title: "Process Followed by our Webflow Agency",
    para: "We provide complete and personalized Webflow development services based on your project requirements, ensuring seamless integration and business success.",
    img: ["how-we-conduct-webflow-services.webp"],
    imageTitle: 'Webflow Process',
    imageAlt: 'Octet’s Webflow development process',
    content: [
      {
        title: "1. Define UX and Flows",
        text: "We initially identify the UX characteristics and user processes to determine the design structure.",
      },
      {
        title: "3. Create HTML Templates",
        text: "We transform the UI design into HTML templates, which are then translated into webflow pages.",
      },
      {
        title: "2. Create UI Designs",
        text: "We create designs while considering brand requirements, typographic design principles, color theory, and UX rules.",
      },
      {
        title: "4. Convert to Webflow Pages",
        text: "Finally, we transfer the HTML code to the Shopify framework for easy comprehension and faster deployment.",
      },
    ],
  };
  const commonContent5 = {
    title:
      "Why Invest in the Best Webflow Development Agency?",
    para: "Webflow is a progressive framework for developing dynamic and interactive interfaces. Investing in our best Webflow Development Agency enables your company to create cost-effective, high-quality websites.",
    content: [
      {
        title: "30% Less Development Time",
        text: "Webflow Development’s simplicity and reactivity can help accelerate development cycles, lowering development time and other resource expenditures.",
      },
      {
        title: "20% Code Reusability",
        text: "The Webflow website development framework's component-based architecture promotes code reuse and maintainability, potentially increasing returns.",
      },
      {
        title: "10% Increased Productivity",
        text: "Webflow development’s excellent documentation and component-based architecture increase developer productivity by up to 10%.",
      }, 
      {
        title: "10% Increased Productivity",
        text: "Webflow’s excellent documentation and component-based architecture increase developer productivity by up to 10%.",
      },
    ],
    desc: "*These statistics are from public sources; we do not own them.",
  };
  const cta = {
    title: "Start your Dream Project with Us",
  };
  const cta2 = {
    title: "Get Expertise From Concept to Launch",
  };
  const cta3 = {
    title: "Thrive your Webflow Project with us",
  };
  const cta4 = {
    title: "Complete your Project Goals with Webflow Development",
  };
  const cta5 = {
    title: "Start Your Webflow Project Today!",
  };
  const tabs = [
    {
      link: '/angularjs-development/',
      text: 'Angular Development'
    },
    {
      link: '/branding/',
      text: 'Branding'
    },
    {
      link: '/corporate-training-workshop/',
      text: 'UI UX Workshops and Training'
    },
    {
      link: '/data-visualisation/',
      text: 'Dashboards and Data Visualisation'
    },
    {
      link: '/design-audit/',
      text: 'Design Audit '
    },
    {
      link: '/design-systems/',
      text: 'Design System Engineering'
    },
    {
      link: '/digital-transformation/',
      text: 'Digital Transformation'
    },
    {
      link: '/product-development/',
      text: 'Product Development'
    },
    {
      link: '/rapid-prototyping/',
      text: 'Rapid Prototyping'
    },
    {
      link: '/reactjs-development/',
      text: 'ReactJS Development'
    },
    {
      link: '/ui-development/',
      text: 'UI Development'
    },
    {
      link: '/ui-ux-designing/',
      text: 'UI UX Designing'
    },
    {
      link: '/usability-testing/',
      text: 'Usability Testing'
    },
    {
      link: '/user-acquisition-consulting/',
      text: 'User Acquisition Consultation'
    },
    {
      link: '/user-research/',
      text: 'User Research'
    },
    {
      link: '/ux-consulting/',
      text: 'UX Consultation'
    },
    {
      link: '/vuejs-development/',
      text: 'VueJS Development'
    },
    {
      link: '/wordpress-development/',
      text: 'WordPress Development'
    },
  ];
  const projectData = [
    {
        title: "Hala Gig",
        desc: "A Consumer Experience Platform",
        Industry: "SaaS",
        firstAlt: "Mobile UI Design To Collect Customer's Response",
        firstTitle: "Feedback Collection Screen",
        secondAlt: "UI Design Of Agent's Account",
        secondTitle: "Agent's Account",
        WhatWeDid: [
          {
            title: "User Research",
            link: "/user-research/",
          },
          {
            title: "UI UX Designing",
            link: "/ui-ux-designing/",
          },
          {
            title: "React Development",
            link: "/reactjs-development/",
          },
        ],
        link: "/project/ui-ux-design-halagig/",
        images: ["hala-gig.webp", "hala-gig-2.webp"],
    },
    {
        title: "Elastic Sign",
        desc: "A Digital Signature Platform",
        Industry: "SaaS",
        firstAlt: "UI Design Of Document Management Screen",
        firstTitle: "Document Management Screen Design",
        secondAlt: "UI UX Design To Sign Document Digitally",
        secondTitle: "E-Sign Documents",
        WhatWeDid: [
          {
            title: "User Research",
            link: "/user-research/",
          },
          {
            title: "UI UX Designing",
            link: "/ui-ux-designing/",
          },
          {
            title: "React Development",
            link: "/reactjs-development/",
          },
        ],
        link: "/project/elastic-sign/",
        images: ["elastic-sign.webp", "elastic-sign-2.webp"],
    },
  ];

  const Questions = [
    "Can you provide custom Webflow Development Services?",
    "Can you integrate Webflow into existing web applications?",
    "How does your Webflow Development Company improve user experience?",
    "What is the duration and cost of your Webflow Website Development Services?",
  ];
  const clientsTitle = "Trusted By Clients Worldwide";
  const faqDes =
    `We understand the questions you may have concerning our Webflow Development Services. In this area, we share additional information on our Webflow Development Company. However, if you still have any questions, please <a title='contact us' target="_blank" rel="noreferrer" href="/contact-us/">contact us</a>.`;
  
    const faqData = [
      {
        para: [
          `Yes, we apply a flexible approach to offer customized solutions for your business to meet your specific demands.`,
          `Webflow development is a flexible framework that we use for customization, and it offers robust web and app solutions.`,
          `To offer tailor-made Webflow development services and enhance your user experience, we understand your business objectives, target audience, and specific project requirements.`
        ],
      },
      {
        para: [
          `Yes, we can seamlessly integrate our Webflow Development Services into existing applications because of the platform's versatility and flexibility.`,
          `We consider Webflow to be the best option for enhancing the functionality of your application.`,
          `It assists in improving your business's performance, enhances user experience, and offers easily scalable options.`
        ],
      },
      {
        para: [
          `Our Webflow development company enables us to provide highly creative and responsive user interfaces based on your business requirements.`,
          `We develop dynamic websites through Webflow and update them using the latest technologies to create an engaging interface.`,
          `With our flexible approach and library, we offer optimized websites, improved usability, and an intuitive user experience.`
        ],
      },
      {
        para: [
          `The duration and cost of our Webflow Website Development Services depend on various factors, such as the complexity and scope of your project, customizations needed, integration required, and more. Hence, we request that you <a href='/contact-us/' title='contact us' target='_blank'>contact us</a> for an accurate timeline and detailed quotation.`
        ],
      }
    ];
  
    return (
      <>
        <Helmet>
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [{
                  "@type": "Question",
                  "name": "Can you provide custom Webflow Development Services?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, we apply a flexible approach to offer customized solutions for your business to meet your specific demands.
              Webflow development is a flexible framework that we use for customization, and it offers robust web and app solutions.
              To offer tailor-made Webflow development services and enhance your user experience, we understand your business objectives, target audience, and specific project requirements."
                  }
                },{
                  "@type": "Question",
                  "name": "Can you integrate Webflow into existing web applications?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, we can seamlessly integrate our Webflow Development Services into existing applications because of the platform's versatility and flexibility.
              We consider Webflow to be the best option for enhancing the functionality of your application.
              It assists in improving your business's performance, enhances user experience, and offers easily scalable options."
                  }
                },{
                  "@type": "Question",
                  "name": "How does your Webflow Development Company improve user experience?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Our Webflow development company enables us to provide highly creative and responsive user interfaces based on your business requirements.
              We develop dynamic websites through Webflow and update them using the latest technologies to create an engaging interface.
              With our flexible approach and library, we offer optimized websites, improved usability, and an intuitive user experience."
                  }
                },{
                  "@type": "Question",
                  "name": "What is the duration and cost of your Webflow Website Development Services?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The duration and cost of our Webflow Website Development Services depend on various factors, such as the complexity and scope of your project, customizations needed, integration required, and more. Hence, we request that you contact us for an accurate timeline and detailed quotation."
                  }
                }]
              }                                
            `}
          </script>
        </Helmet>
        <Layout>
          <div className="service-detail-page">
            <Banner content={banner} page={"service"} />
            <CommonServiceComponent data={commonContent6} mode={"grey"} />
            <ServiceTab tabs={tabs} />
            <CommonServiceComponent data={commonContent} mode={"light"} />
            <Cta mode="grey" data={cta} />
            <CommonServiceComponent data={commonContent2} mode={"dark"} />
            <CommonServiceComponent data={commonContent3} mode={"light"} />
            <Cta data={cta2} />
            <Clients title={clientsTitle} />
            <ServiceTab tabs={tabs} />
            <CommonServiceComponent data={commonContent4} mode={"light"} />
            <Cta mode="grey" data={cta3} />
            <section className="project-section lg:py-[200px] md:py-[120px] py-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
              <div className="container">
                <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] tracking-[.01em]">
                    Our Successful Projects by <span className="h1-span">Webflow Development</span>
                </h2>
              </div>
              {projectData.map((data, index) => (
                <div key={index}>
                  <ProjectSection num={index} lastLength={projectData} data={data} />
                </div>
              ))}
            </section>
            <Testimonials title={"Client’s Take On Us"} />
            <Cta data={cta4} />
            <CommonServiceComponent data={commonContent5} condition mode={"light"} />
            <ServiceTab tabs={tabs} mode={"split"} />
            <Faq Questions={Questions} section des={faqDes} link faqData={faqData} />
            <Cta mode="grey" data={cta5} />
            {/* <KnowMoreSection /> */}
          </div>
        </Layout>
      </>
  );
};

export default ServiceDetail;

export const Head = () => (
  <Seo title="Webflow Development Agency | Webflow Development Services" description="Your go-to Webflow development agency that focuses on providing responsive Webflow development services to bring you more customers." />
)